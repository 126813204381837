import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../Utils/Api";

export const getAllServices = createAsyncThunk(
  "getAllServices",
  async (url) => {
    try {
      const res = await http.get(url);
      return res.data;
    } catch (error) {
      return { data: null };
    }
  }
);
export const getSubServices = createAsyncThunk(
  "getSubServices",
  async (url) => {
    try {
      const res = await http.get(url);
      return res.data;
    } catch (error) {
      return { data: null };
    }
  }
);
export const getSubServicesProducts = createAsyncThunk(
  "getSubServicesProducts",
  async (url) => {
    try {
      const res = await http.get(url);
      return res.data;
    } catch (error) {
      return { data: null };
    }
  }
);
export const getSingleService = createAsyncThunk(
  "getSingleService",
  async (url) => {
    try {
      const res = await http.get(url);
      return res.data;
    } catch (error) {
      return { data: null };
    }
  }
);

const serviceSlice = createSlice({
  name: "services",
  initialState: {
    allServices: null,
    singleService: null,
    loading: false,
    subServiecs: null,
    subServiecsProducts: null,
    subServiecsProductPage: null,
    singleServiceReviews: null,
    reviewPage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllServices.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllServices.fulfilled, (state, action) => {
        state.loading = false;
        state.allServices = action?.payload?.data;
      })
      .addCase(getAllServices.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSubServices.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSubServices.fulfilled, (state, action) => {
        state.loading = false;
        state.subServiecs = action?.payload?.data;
      })
      .addCase(getSubServices.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSubServicesProducts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSubServicesProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.subServiecsProducts = action?.payload?.data;
        state.subServiecsProductPage = action?.payload?.page;
      })
      .addCase(getSubServicesProducts.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSingleService.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSingleService.fulfilled, (state, action) => {
        state.loading = false;
        state.singleService = action?.payload?.data;
        state.singleServiceReviews = action?.payload?.review;
        state.reviewPage = action?.payload?.reviewPage;
      })
      .addCase(getSingleService.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export default serviceSlice.reducer;
