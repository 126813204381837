import { Box, Typography } from "@mui/material";
import React from "react";
import { handleSwitch } from "../Redux/festures/orderSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const NotiCard = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          boxShadow:
            "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          width: "100%",
          height: "5rem",
          borderRadius: 3,
          bgcolor: data?.color,
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
          px: 2,
        }}
        onClick={() => {
          if (data?.type === "ECOM_ORDERED") {
            dispatch(handleSwitch(true));

            navigate("/order-page", { state: data?.orderId });
          } else if (data?.type === "ORDERED") {
            dispatch(handleSwitch(false));
            navigate("/order-page", { state: data?.orderId });
          }
        }}
      >
        <Box
          sx={{
            width: "3rem",
            height: "3rem",
            borderRadius: 10,
            bgcolor: data?.bgcolor,
          }}
        >
          <img 
 draggable="false" 
loading="lazy"
            src={require("../assets/images/noti1.png")}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt="..."
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../assets/images/r1.png");
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "80%",
          }}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{ fontSize: 15, fontWeight: 600 }}
          >
            {data?.title}
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            sx={{ fontSize: 15, fontWeight: 400 }}
          >
            {data?.message}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default NotiCard;
