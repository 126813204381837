import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  Slide,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Close, Mail } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { handleAlert } from "../../Redux/alertSlice";
import { getUserDetails, updateUsers } from "../../Redux/festures/userSlice";
import { handleProfileOpen } from "../../Redux/ProfileSlice";
import { Link } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileModal = ({ width, height, overflowY }) => {
  const isMobile = useMediaQuery("(max-width:870px)");

  const { isOpen } = useSelector((state) => state.isProfileModal);
  const { loading, userData } = useSelector((state) => state.isUsers);
  // console.log(userData?.fullName, "userData");/
  // const [fullName, setFullName] = useState("");
  const [images, setImages] = useState("");
  const [previewImage, setPreviewImage] = useState(null);

  const dispatch = useDispatch();
  const isUserId = localStorage.getItem("isUserId");

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    setImages(file);
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader.result);
    });
    fileReader.readAsDataURL(file);
  };

  // const handleSubmitForm = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("fullName", fullName);
  //   formData.append("image", images);

  //   dispatch(
  //     updateUsers({
  //       url: `${process.env.REACT_APP_API}/updateUser/${isUserId}`,
  //       data: formData,
  //     })
  //   ).then((data) => {
  //     dispatch(
  //       handleAlert({
  //         isOpen: true,
  //         type: data?.payload?.success ? "success" : "error",
  //         msg: data?.payload?.message,
  //       })
  //     );
  //     if (data?.payload?.success) {
  //       dispatch(getUserDetails(`/getUserById/${isUserId}`));
  //       dispatch(handleProfileOpen(false));
  //     }
  //   });
  // };

  const validationSchema = yup.object().shape({
    fullName: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain Latin letters."
      )
      .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, "Please enter your full name.")
      .min(2, "Too Short!")
      .max(32, "Too Long!")
      .required("Full name is required"),
    email: yup
      .string()

      .required("Email is required")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        "Invalid email address"
      ),
  });
  const initialValues = {
    fullName: "",
    email: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log(values);
      const formData = new FormData();
      formData.append("fullName", values?.fullName);
      formData.append("image", images);

      dispatch(
        updateUsers({
          url: `${process.env.REACT_APP_API}/updateUser/${isUserId}`,
          data: formData,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: data?.payload?.success ? "success" : "error",
            msg: data?.payload?.message,
          })
        );
        if (data?.payload?.success) {
          dispatch(getUserDetails(`/getUserById/${isUserId}`));
          dispatch(handleProfileOpen(false));
        }
      });
    },
  });

  useEffect(() => {
    if (userData?.fullName || userData?.email) {
      // setFullName(userData?.fullName);
      formik.setFieldValue("fullName", userData?.fullName);
      formik.setFieldValue("email", userData?.email);
    }
  }, [userData, userData?.fullName, userData?.email]);
  return (
    <Modal
      TransitionComponent={Transition}
      open={isOpen}
      onClose={() => dispatch(handleProfileOpen(false))}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
    >
      <Box
        sx={{
          backgroundColor: "white.main",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "90%" : width ? width : "auto",
          height: height ? height : "auto",
          borderRadius: 1.5,
          boxShadow: 24,
          // p: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <IconButton
          onClick={() => {
            dispatch(handleProfileOpen(false));
          }}
          sx={{
            color: "primary.main",
            position: "fixed",
            top: { xs: -35.5, sm: -35.5, md: 5, xl: 5 }, //5,
            right: { xs: 5, sm: 5, md: -35.5, xl: -35.5 }, //</Box>-35.5,

            bgcolor: "white.main",
            zIndex: 999,
            width: 25,
            height: 25,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              color: "primary.main",
              bgcolor: "white.main",
            },
          }}
        >
          <Close
            sx={{
              fontSize: 20,
            }}
          />
        </IconButton>

        <Box
          sx={{
            backgroundColor: "transparent",

            width: "100%",
            height: "100%",
            overflowY: overflowY ? "scroll" : "",
            // bgcolor: darkMode ? white.main : dark.main,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // p: padding ? padding : 4,
            py: 4,
            px: 4,
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              width: "0.4em",
              display: "none",
            },
          }}
        >
          <form
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "15px",
            }}
            onSubmit={formik.handleSubmit}
          >
            <Box
              sx={{
                mt: 0.6,
                height: 80,
                width: 80,
                border: 2,
                borderColor: "primary.main",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <label htmlFor="imageuploader">
                {" "}
                <img 
 draggable="false" 
loading="lazy"
                  src={require("../../assets/images/edit.png")}
                  alt="edit button"
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    position: "absolute",
                    bottom: "-5px",
                    right: "-5px ",
                    cursor: "pointer",
                    borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              </label>
              <input
                // required
                type="file"
                accept="image/*"
                hidden
                onChange={handleSelectImage}
                id={"imageuploader"}
              />

              <img 
 draggable="false" 
loading="lazy"
                src={
                  previewImage
                    ? previewImage
                    : userData?.image
                    ? `${process.env.REACT_APP_URI}/${userData?.image}`
                    : ""
                }
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = require("../../assets/images/avatar.jpg");
                }}
                alt="edit button"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Box>

            <Box
              sx={{
                mt: 1,
                width: "100%",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "primary.main", fontWeight: 600 }}
              >
                Full name
              </Typography>
              <TextField
                fullWidth
                // required
                autoComplete="off"
                variant="outlined"
                placeholder="Full name"
                type={"text"}
                name="fullName"
                // defaultValue={userData && userData?.fullName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.fullName}
                error={!!formik.touched.fullName && !!formik.errors.fullName}
                helperText={formik.touched.fullName && formik.errors.fullName}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 45,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "#E8E8E8",
                    color: "primary.main",
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                mt: 1,
                width: "100%",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "primary.main", fontWeight: 600 }}
              >
                Email
              </Typography>
              <TextField
                fullWidth
                // required
                // disabled
                autoComplete="off"
                variant="outlined"
                placeholder="Email"
                // helperText="Mobile Number "
                type={"email"}
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                error={!!formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 45,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "#E8E8E8",
                    color: "primary.main",
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                mt: 1,
                width: "100%",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "primary.main", fontWeight: 600 }}
              >
                Phone Number
              </Typography>
              <TextField
                fullWidth
                // required
                disabled
                autoComplete="off"
                variant="outlined"
                placeholder="Mobile number"
                // helperText="Mobile Number "
                type={"number"}
                defaultValue={userData && userData?.phoneNumber}
                //   onChange={(e) =>
                //     setAstroData2((prev) => ({
                //       ...prev,
                //       dailyConHour: e.target.value,
                //     }))
                //   }
                sx={{
                  "& .MuiInputBase-root": {
                    height: 45,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "#E8E8E8",
                    color: "primary.main",
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>

            <LoadingButton
              loading={loading}
              variant="contained"
              type="submit"
              color="primary"
              sx={{ textTransform: "capitalize", px: 2.5, py: 1 }}
            >
              Update Profile
            </LoadingButton>

            <Button
              variant="text"
              sx={{
                textTransform: "capitalize",
                fontWeight: 600,
              }}
              component={Link}
              to="/account_delete"
              onClick={() => {
                dispatch(handleProfileOpen(false));
              }}
            >
              Delete Account
            </Button>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProfileModal;
