import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IconButton, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { handleModalOpen } from "../../Redux/LoginSlice";

import Login from "./Login";
import Register from "./Register";
import OtpPage from "./Otp";
import { useLocation } from "react-router-dom";
export default function LoginModal({
  // children,
  // show,
  // unShow,
  width,
  height,
  overflowY,
  padding,
  title,
}) {
  const isMobile = useMediaQuery("(max-width:870px)");

  const { isOpen } = useSelector((state) => state.isLoginModal);
  const dispatch = useDispatch();
  // console.log(isOpen, "slice");
  const [click, setClick] = React.useState(true);
  const [isShow, setIsShow] = React.useState(true);
  const [otpVerify, setOtpVerify] = React.useState(false);
  const [mobileNumber, setMobileNumber] = React.useState(null);

  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const sendOTP = () => {
    setMinutes(1);
    setSeconds(0);
  };
  const location = useLocation();
  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={() => dispatch(handleModalOpen(false))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
      >
        <Box
          sx={{
            backgroundColor: "white.main",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "90%" : width ? width : "auto",
            height: height ? height : "auto",
            borderRadius: 1.5,
            boxShadow: 24,
            // p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <IconButton
            onClick={() => {
              setClick(true);
              setIsShow(true);
              setOtpVerify(false);
              dispatch(handleModalOpen(false));
              setMobileNumber(null);
              localStorage.removeItem("isUserId");
              localStorage.removeItem("isToken");
              localStorage.removeItem("userVerify");
            }}
            disabled={location.pathname === "/profile-page"}
            sx={{
              color: "primary.main",
              position: "fixed",
              top: -35.5,
              right: 0,

              bgcolor: "white.main",
              zIndex: 999,
              width: 25,
              height: 25,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "primary.main",
                bgcolor: "white.main",
              },
            }}
          >
            <Close
              sx={{
                fontSize: 20,
              }}
            />
          </IconButton>

          <Box
            sx={{
              backgroundColor: "transparent",

              width: { xs: "100%", sm: "100%", md: "80%", xl: "80%" },
              height: "100%",
              overflowY: overflowY ? "scroll" : "",
              // bgcolor: darkMode ? white.main : dark.main,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // p: padding ? padding : 4,
              py: 6,
              px: 4,
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                width: "0.4em",
                display: "none",
              },
            }}
          >
            {click && isShow && (
              <Login
                setClick={setClick}
                setOtpVerify={setOtpVerify}
                setIsShow={setIsShow}
                setMobileNumber={setMobileNumber}
                sendOTP={sendOTP}
                mobileNumber={mobileNumber}
              />
            )}
            {!click && isShow && (
              <Register
                setClick={setClick}
                setOtpVerify={setOtpVerify}
                setIsShow={setIsShow}
                sendOTP={sendOTP}
                mobileNumber={mobileNumber}
              />
            )}

            {otpVerify && (
              <OtpPage
                setClick={setClick}
                setIsShow={setIsShow}
                setOtpVerify={setOtpVerify}
                minutes={minutes}
                seconds={seconds}
                sendOTP={sendOTP}
                setMobileNumber={setMobileNumber}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
