import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";

export default function WhatsappFloating() {
  return (
    <FloatingWhatsApp
      phoneNumber={"9329932726"}
      accountName={"Ess India Online Support"}
      avatar={require("../../assets/images/logo.png")}
    />
  );
}
