import { Box, Button } from "@mui/material";
import React from "react";
import img1 from "../../assets/images/1svg1.svg";
import img2 from "../../assets/images/1svg2.svg";
import img3 from "../../assets/images/1svg3.svg";
import img4 from "../../assets/images/1svg4.svg";
import img5 from "../../assets/images/1svg5.svg";
import { useNavigate } from "react-router-dom";

const SkError = ({ type, msg, width, height, EComm , rate}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: width ? width : "100%",
        height: height ? height : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
        m: "auto",
        p: "auto",
      }}
    >
      <Box sx={{ width: "100%", height: "auto" }}>
        {(type === "Products" && (
          <img 
 draggable="false" 
loading="lazy"
            src={img1}
            alt={"...."}
            style={{ height: "100%", width: "100%" }}
          />
        )) ||
          (type === "Cart" && (
            <img 
 draggable="false" 
loading="lazy"
              src={img2}
              alt={"...."}
              style={{ height: "100%", width: "100%" }}
            />
          )) ||
          (type === "Coupon" && (
            <img 
 draggable="false" 
loading="lazy"
              src={img2}
              alt={"...."}
              style={{ height: "100%", width: "100%" }}
            />
          )) ||
          (type === "Login" && (
            <img 
 draggable="false" 
loading="lazy"
              src={img4}
              alt={"...."}
              style={{ height: "100%", width: "100%" }}
            />
          )) ||
          (type === "Error" && (
            <img 
 draggable="false" 
loading="lazy"
              src={img5}
              alt={"...."}
              style={{ height: "100%", width: "100%" }}
            />
          )) ||
          (type === "data" && (
            <img 
 draggable="false" 
loading="lazy"
              src={img3}
              alt={"...."}
              style={{ height: "100%", width: "100%" }}
            />
          ))}
      </Box>
      {type !== "Error" && <p>No More {type} to Load....</p>}
      {msg && msg}
    {!rate && <Button
        variant="outlined"
        color="primary"
        onClick={() =>
          EComm ? navigate("/e_home-page") : navigate("/")
        }
      >
        go back to more {EComm ? "Products/Category" : "services"}
      </Button>}
    </Box>
  );
};

export default SkError;
