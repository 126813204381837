import { lazy } from "react";

const Home = lazy(() => import("./pages/home"));
const Services = lazy(() => import("./pages/services"));
const About = lazy(() => import("./pages/About"));
const PrivatePolicy = lazy(() => import("./pages/PrivacyPolicy"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Faq = lazy(() => import("./pages/Faq"));

const Cart = lazy(() => import("./pages/Cart"));
const Order = lazy(() => import("./pages/Order"));
const Productdetail = lazy(() => import("./pages/Productdetail"));

const OrderSummary = lazy(() => import("./pages/OrderSummary/OrderSummary"));
const ServiceDetailsPage = lazy(() => import("./pages/ServiceDtails page"));
const TermsAndCondition = lazy(() => import("./pages/TermsAndCondition"));
const EcommServiceDetails = lazy(() =>
  import("./pages/ServiceDtails page/E_ServiceDetails")
);
const EcommServices = lazy(() => import("./pages/services/EcommServices"));
const EcommHome = lazy(() => import("./pages/home/EcommHome"));
const EcommCart = lazy(() => import("./pages/Cart/EcommCart"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const Searchresult = lazy(() => import("./pages/Search/Searchresult"));
const ProfessionalSection = lazy(() => import("./pages/ProfessionalSection"));
const OrderHistoryDetails = lazy(() =>
  import("./pages/Order/OrderHistoryDetails")
);
const PaymentStatus = lazy(() => import("./pages/Notification/PaymentStatus"));
const Notification = lazy(() => import("./pages/Notification/Notification"));
const ShippingAndDeliveryPage = lazy(() =>
  import("./pages/policys/ShippingAndDeliveryPage")
);
const RefundReturnPage = lazy(() => import("./pages/policys/RefundReturnPage"));
const AccountDelete = lazy(() => import("./pages/AccountDelete"));

const routes = [
  {
    type: "route",
    name: "home",
    key: "home",
    route: "/",
    component: <Home />,
  },
  {
    type: "route",
    name: "About",
    key: "About",
    route: "/about-page",
    component: <About />,
  },
  {
    type: "route",
    name: "profile",
    key: "profile",
    route: "/profile-page",
    component: <ProfilePage />,
  },

  {
    type: "route",
    name: "PrivatePolicy",
    key: "PrivatePolicy",
    route: "/privacy-Policy-page",
    component: <PrivatePolicy />,
  },
  {
    type: "route",
    name: "ShippingAndDeliveryPage",
    key: "ShippingAndDeliveryPage",
    route: "/shipping-and-delivery-page",
    component: <ShippingAndDeliveryPage />,
  },
  {
    type: "route",
    name: "RefundReturnPage",
    key: "RefundReturnPage",
    route: "/refund-policy-page",
    component: <RefundReturnPage />,
  },
  {
    type: "route",
    name: "TermsAndCondition",
    key: "TermsAndCondition",
    route: "/temsandconditon-page",
    component: <TermsAndCondition />,
  },
  {
    type: "route",
    name: "ContactUs",
    key: "ContactUs",
    route: "/contact-page",
    component: <ContactUs />,
  },
  {
    type: "route",
    name: "Faq",
    key: "Faq",
    route: "/faq-page",
    component: <Faq />,
  },
  {
    type: "route",
    name: "Order",
    key: "Order",
    route: "/order-page",
    component: <Order />,
  },
  {
    type: "route",
    name: "Cart",
    key: "Cart",
    route: "/cart-page",
    component: <Cart />,
  },
  {
    type: "route",
    name: "services",
    key: "services",
    route: "/services-page/:id",
    component: <Services />,
  },

  {
    type: "route",
    name: "Productdetail",
    key: "Productdetail",
    route: "/productdetail-page",
    component: <Productdetail />,
  },
  {
    type: "route",
    name: "serviceDetails",
    key: "serviceDetails",
    route: "/serviceDetails-page/:id",
    component: <ServiceDetailsPage />,
  },

  {
    type: "route",
    name: "Searchresult",
    key: "Searchresult",
    route: "/search-page",
    component: <Searchresult />,
  },
  // {
  //   type: "route",
  //   name: "AddAddress",
  //   key: "AddAddress",
  //   route: "/addAddress-page",
  //   component: <AddAddress />,
  // },

  // {
  //   type: "route",
  //   name: "Payment",
  //   key: "Payment",
  //   route: "/payment-page",
  //   component: <Payment />,
  // },

  // {
  //   type: "route",
  //   name: "CartMobile",
  //   key: "CartMobile",
  //   route: "/cartMobile-page",
  //   component: <Cartmobile />,
  // },
  {
    type: "route",
    name: "OrderSummary",
    key: "OrderSummary",
    route: "/orderSummary-page",
    component: <OrderSummary />,
  },
  {
    type: "route",
    name: "EHome",
    key: "EHome",
    route: "/e_home-page",
    component: <EcommHome />,
  },
  {
    type: "route",
    name: "EService",
    key: "EService",
    route: "/e_categories-page/:id",
    component: <EcommServices />,
  },
  {
    type: "route",
    name: "ECart",
    key: "ECart",
    route: "/e_cart-page",
    component: <EcommCart />,
  },
  {
    type: "route",
    name: "e_serviceDetails",
    key: "e_serviceDetails",
    route: "/e_categoriesDetails/:id",
    component: <EcommServiceDetails />,
  },
  {
    type: "route",
    name: "ProfessionalSection",
    key: "ProfessionalSection",
    route: "/professionalRegistration",
    component: <ProfessionalSection />,
  },
  {
    type: "route",
    name: "OrderHistoryDetails",
    key: "OrderHistoryDetails",
    route: "/orderHistoryPage",
    component: <OrderHistoryDetails />,
  },
  {
    type: "route",
    name: "payment",
    key: "payment",
    route: "/paymentstauspage",
    component: <PaymentStatus />,
  },
  {
    type: "route",
    name: "notification",
    key: "notification",
    route: "/notification",
    component: <Notification />,
  },
  {
    type: "route",
    name: "accountDelete",
    key: "accountDelete",
    route: "/account_delete",
    component: <AccountDelete />,
  },
];
export default routes;
