import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const PaymentModal = () => {
  const { isPaymentOpen, url } = useSelector((state) => state.isProfileModal);
  // console.log("url", url);
  return (
    <Dialog open={isPaymentOpen} fullWidth maxWidth="md">
      <DialogContent>
        <IconButton
          edge="end"
          color="inherit"
          //   onClick={() => onClose}
          aria-label="close"
          sx={{ position: "absolute", top: "8px", right: "8px" }}
        >
          <CloseIcon />
        </IconButton>
        <iframe
          title="Payment"
          src={url}
          width="100%"
          height="500px"
          frameBorder="0"
        />
      </DialogContent>
    </Dialog>
  );
};

export default PaymentModal;
