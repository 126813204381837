export const NotificationBellIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
    >
      <path
        d="M7.60374 21.5349C8.19187 21.4027 11.7756 21.4027 12.3638 21.5349C12.8665 21.6583 13.4102 21.9466 13.4102 22.576C13.381 23.1753 13.0501 23.7066 12.5929 24.044C12.0001 24.535 11.3044 24.8459 10.5771 24.958C10.1749 25.0134 9.77971 25.0146 9.39152 24.958C8.66308 24.8459 7.96738 24.535 7.37574 24.0427C6.91739 23.7066 6.5865 23.1753 6.55726 22.576C6.55726 21.9466 7.10097 21.6583 7.60374 21.5349ZM10.0532 0C12.5004 0 15.0003 1.23377 16.4852 3.28083C17.4487 4.59895 17.8907 5.91582 17.8907 7.96288V8.49541C17.8907 10.0653 18.2812 10.9907 19.1406 12.057C19.7919 12.8426 20 13.851 20 14.945C20 16.0378 19.6621 17.0752 18.9851 17.9174C18.0988 18.9271 16.8489 19.5717 15.5732 19.6837C13.7246 19.8512 11.8749 19.9922 10.0006 19.9922C8.12511 19.9922 6.27653 19.9078 4.42795 19.6837C3.15113 19.5717 1.9012 18.9271 1.01608 17.9174C0.339082 17.0752 0 16.0378 0 14.945C0 13.851 0.209296 12.8426 0.859398 12.057C1.74569 10.9907 2.11049 10.0653 2.11049 8.49541V7.96288C2.11049 5.86042 2.60392 4.48565 3.61999 3.13983C5.13066 1.17712 7.55218 0 9.94797 0H10.0532Z"
        fill="#FF4B3C"
      />
    </svg>
  );