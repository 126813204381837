import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../Utils/Api";
import axios from "axios";

export const getCartData = createAsyncThunk("getCartData", async (url) => {
  try {
    const res = await http.get(url);
    return res?.data;
  } catch (error) {
    return { data: null };
  }
});
export const getEcommCartData = createAsyncThunk(
  "getEcommCartData",
  async (url) => {
    try {
      const res = await http.get(url);
      return res?.data;
    } catch (error) {
      return { data: null };
    }
  }
);
export const createCartData = createAsyncThunk(
  "createCartData",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const updateCart = createAsyncThunk(
  "updateCart",
  async ({ url, data }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          authorization: localStorage.getItem("isToken"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);
export const deleteCart = createAsyncThunk("deleteCart", async ({ url }) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: localStorage.getItem("isToken"),
      },
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
});

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    loading: false,
    cartData: null,
    ecommCartData: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCartData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCartData.fulfilled, (state, action) => {
        state.loading = false;
        state.cartData = action?.payload?.data;
      })
      .addCase(getCartData.rejected, (state, action) => {
        state.loading = false;
        // state.cardData = action?.payload?.data;
      })
      .addCase(getEcommCartData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEcommCartData.fulfilled, (state, action) => {
        state.loading = false;
        state.ecommCartData = action?.payload?.data;
      })
      .addCase(getEcommCartData.rejected, (state, action) => {
        state.loading = false;
        // state.cardData = action?.payload?.data;
      });
  },
});
export default cartSlice.reducer;
