import React from "react";
import "./loading.css";
import { Box } from "@mui/material";

const LoadingComponents = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: 2.5,
      }}
    >
      <div className="container_body">
        <span className="loader_loading__1"></span>
      </div>
      <h6
        style={{
          textAlign: "center",
          marginTop: 25,
          fontSize: 25,
          color: "#c42524",
        }}
      >
        Loading...
      </h6>
    </Box>
  );
};

export default LoadingComponents;
