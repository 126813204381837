import { createSlice } from "@reduxjs/toolkit";

const orderModalSlice = createSlice({
  name: "LoginModal",
  initialState: {
    isOpen: false,
    isId: null,
    EComm: false,
  },

  reducers: {
    handleOrderOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    handleSetOrderId: (state, action) => {
      state.isId = action.payload;
    },
    handleSetOrderEComm: (state, action) => {
      state.EComm = action.payload;
    },
  },
});

export const { handleOrderOpen, handleSetOrderId, handleSetOrderEComm } =
  orderModalSlice.actions;

export default orderModalSlice.reducer;
