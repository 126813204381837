
const ProductCardData = [
  {
    name: "AC Repair",
  },
  {
    name: "Appliance",
  },
  {
    name: "Cleaning",
  },
  {
    name: "Mini Fridge",
  },
  {
    name: "Laundry",
  },
  {
    name: "24/7 WI-FI",
  },
];
const ProductImageData = [
  {
    image: require("../../assets/images/surprised-happy-girl-pointing-left-recommend-product-advertisement-make-okay-gesture.jpg"),
    name: "Average, works hard but still not up to the mark as expected. So right now I am not that much impressed, hope will get better performace next time.",
  },
  {
    image: require("../../assets/images/surprised-happy-girl-pointing-left-recommend-product-advertisement-make-okay-gesture.jpg"),
    name: "Good service.",
  },
  {
    image: require("../../assets/images/surprised-happy-girl-pointing-left-recommend-product-advertisement-make-okay-gesture.jpg"),
    name: "Average, works hard but still not up to the mark as expected. So right now I am not that much impressed, hope will get better performace next time.",
  },
  {
    image: require("../../assets/images/surprised-happy-girl-pointing-left-recommend-product-advertisement-make-okay-gesture.jpg"),
    name: "Average, works hard but still not up to the mark as expected. So right now I am not that much impressed, hope will get better performace next time.",
  },
  {
    image: require("../../assets/images/surprised-happy-girl-pointing-left-recommend-product-advertisement-make-okay-gesture.jpg"),
    name: "Good service.",
  },
  {
    image: require("../../assets/images/surprised-happy-girl-pointing-left-recommend-product-advertisement-make-okay-gesture.jpg"),
    name: "Average, works hard but still not up to the mark as expected. So right now I am not that much impressed, hope will get better performace next time.",
  },
  {
    image: require("../../assets/images/surprised-happy-girl-pointing-left-recommend-product-advertisement-make-okay-gesture.jpg"),
    name: "Average, works hard but still not up to the mark as expected. So right now I am not that much impressed, hope will get better performace next time.",
  },
];

const MobileProductCardData = [
  {
    image: "",
  },
  {
    image: "",
  },
  {
    image: "",
  },
];
const MobileProductKGCard = [
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
];

export {
  MobileProductKGCard,
  MobileProductCardData,
  ProductCardData,
  ProductImageData,
};

export const pages = [
  {
    name: "Home",
    link: "/",
  },
  // {
  //   name: "Service",
  //   link: "/services-page",
  // },
  {
    name: "About Us",
    link: "/about-page",
  },
  {
    name: "Contact Us",
    link: "/contact-page",
  },
];
