import React, { useEffect } from "react";
import Footer from "../Footer";
import Navbaar from "../Navbar/NavBaar";
import MobileComponent from "../Mobile/MobileComponent";
import { useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { getUserDetails } from "../../Redux/festures/userSlice";
import { getCompanyData } from "../../Redux/festures/companySlice";
// import { getCartData } from "../../Redux/festures/cartSlice";
import BottomNavigationBar from "../Footer/BottomNavigationbar";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:870px)");
  const userId = localStorage.getItem("isUserId");
  const userVerify = localStorage.getItem("userVerify");
  useEffect(() => {
    if (userId && userVerify) {
      dispatch(getUserDetails(`/getUserById/${userId}`));
    }
  }, [userId, userVerify]);

  useEffect(() => {
    dispatch(getCompanyData(`/get/company`));
  }, []);

  return (
    <>
      {!isMobile ? (
        <Navbaar isToken={true} Layout={true} />
      ) : (
        <MobileComponent />
      )}
      <main>{children}</main>
      {!isMobile ? <Footer /> : <BottomNavigationBar />}
    </>
  );
};

export default Layout;
