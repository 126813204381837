import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../Utils/Api";
import axios from "axios";

export const getAllCoupons = createAsyncThunk("getAllCoupons", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const updateUserStatus = createAsyncThunk(
  "updateUserStatus",
  async ({ url, data }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          // "Content-Type": "multipart/form-data",
          // Accept: "application/json",
          authorization: localStorage.getItem("token"),
        },
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const couponSlice = createSlice({
  name: "home",
  initialState: {
    coupons: null,
    isLoading: false,
    isPages: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCoupons.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllCoupons.fulfilled, (state, action) => {
        state.coupons = action?.payload?.data;
        state.isPages = action?.payload?.page;
        state.isLoading = false;
      })
      .addCase(getAllCoupons.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export default couponSlice.reducer;
