import {  Close, } from "@mui/icons-material";
// import { LoadingButton } from "@mui/lab";
import {
  Box,
  IconButton,
  Modal,
  useMediaQuery,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOrder } from "../../Redux/festures/orderSlice";
import SkLoading from "../SkError/SkLoading";
import {
  handleOrderOpen,
  // handleSetOrderEComm,
  handleSetOrderId,
} from "../../Redux/OrderModalSlice";
import { useNavigate } from "react-router-dom";
import { SkPrice } from "../../Utils/dataFunction";

const IsOrderCard = ({ value }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 0.5,
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: 1,
          flex: 1,
        }}
      >
        <Box
          sx={{
            width: 150,
            height: { md: 75, xl: 75, sm: "5.5rem", xs: "5.5rem" },
            borderRadius: 0.5
            //  maxHeight: { md: 75, xl: 75, sm: "5.5rem", xs: "5.5rem" },
          }}
        >
          <img 
 draggable="false" 
loading="lazy"
            src={`${process.env.REACT_APP_URI}/${value?.productId?.thumnail}`}
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../../assets/images/banner.png");
            }}
            style={{ height: "100%", width: " 100%", objectFit: "cover" , borderRadius: "4px"}}
            alt="..."
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 0.5,
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontWeight: 700,
              fontSize: 14,
              maxWidth: "100%",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: { md: 2, xl: 2, sm: 1, xs: 1 },
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "left",
            }}
          >
            {value?.productId?.title}
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontWeight: 500,
              fontSize: 14,
              textAlign: "left",
              maxWidth: "80%",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value?.productId?.subtitle}
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            sx={{ fontWeight: 500, fontSize: 14, textAlign: "left" }}
          >
            quantity: {value?.quantity}
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontWeight: 600,
              fontSize: 14,
              display: { md: "none", xl: "none", sm: "block", xs: "block" },
            }}
          >
            Price: {SkPrice(value?.productId?.price)}
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="body1"
        color="initial"
        sx={{
          fontWeight: 600,
          fontSize: 14,
          display: { md: "block", xl: "block", sm: "none", xs: "none" },
        }}
      >
        {SkPrice(value?.productId?.price)}
      </Typography>
    </Box>
  );
};

const OrderModal = ({ width, height, overflowY, padding, title }) => {
  const isMobile = useMediaQuery("(max-width:870px)");
  const dispatch = useDispatch();
  const { isOpen, isId, EComm } = useSelector((state) => state.isOrderModal);
  const { singleOrder, loading } = useSelector((state) => state.isOrders);
  // console.log(singleOrder, "singleOrder");
  const navigate = useNavigate();
  useEffect(() => {
    if (EComm && isId) {
      dispatch(getSingleOrder(`eCommerce/getByOrderId/${isId}`));
    } else if (isId) {
      dispatch(getSingleOrder(`getOrderByOrderId/${isId}`));
    }
  }, [isId, EComm]);

  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={() => dispatch(handleOrderOpen(false))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
      >
        <Box
          sx={{
            backgroundColor: "white.main",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "90%" : width ? width : "60%",
            height: isMobile ? "auto" : "80vh",
            maxHeight: isMobile ? "90%" : "100%",
            borderRadius: 1.5,
            boxShadow: 24,
            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <IconButton
            onClick={() => {
              //   dispatch(handleOrderOpen(false));
            }}
            sx={{
              color: "primary.main",
              position: "fixed",
              top: -35.5,
              right: 0,

              bgcolor: "white.main",
              zIndex: 999,
              width: 25,
              height: 25,
              borderRadius: "50%",
              display: "none",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "primary.main",
                bgcolor: "white.main",
              },
            }}
          >
            <Close
              sx={{
                fontSize: 20,
              }}
            />
          </IconButton>

          <Box
            sx={{
              backgroundColor: "transparent",

              width: "100%",
              height: "auto",
              overflowY: "scroll",
              // bgcolor: darkMode ? white.main : dark.main,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              gap: 1,
              py: { md: 4, xl: 4, sm: 1, xs: 1 },
              px: { md: 4, xl: 4, sm: 1, xs: 1 },
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                width: "0.4em",
                display: "none",
              },
            }}
          >
            {loading ? (
              <SkLoading />
            ) : (
              singleOrder && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    gap: 2,
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="body1" fontWeight={600}>
                      Thanks!
                    </Typography>
                    <Typography variant="h6" fontWeight={800} color="#198754">
                      Successful Order 🚀
                    </Typography>
                    <Typography variant="subtitle" fontWeight={400}>
                      Your order is on the way. It’ll be shipped today. We’ll
                      inform you.
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      height: 3.5,
                      mt: -1,
                      width: "65%",
                      background:
                        "linear-gradient(90deg, #C42524 0%, rgba(33, 150, 243, 0.00) 100%);",
                    }}
                  />

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2" color="initial">
                      {" "}
                      Order Id:{" "}
                      <Typography
                        component={"span"}
                        variant="body2"
                        color="primary"
                      >
                        {singleOrder?._id}
                      </Typography>
                    </Typography>
                    {/* <Button
                      size={"small"}
                      variant="outlined"
                      color="primary"
                      startIcon={<Print />}
                      sx={{ textTransform: "capitalize" }}
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_URI}/${singleOrder?.invoice}`
                        );
                      }}
                    >
                      invoice
                    </Button> */}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: 0.5,
                      flexDirection: "column",
                      border: 0.5,
                      borderColor: "gray.border",
                      borderRadius: 2,
                      p: 1,
                    }}
                  >
                    {singleOrder?.product &&
                      singleOrder?.product?.length > 0 &&
                      singleOrder?.product?.map((data, index) => (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 0.8,
                            flexDirection: "column",
                          }}
                          key={index}
                          style={{ textAlign: "center" }}
                        >
                          <IsOrderCard value={data} key={index} />
                          <Divider
                            width="95%"
                            sx={{
                              display:
                                singleOrder?.product?.length - 1 === index
                                  ? "none"
                                  : "flex",
                            }}
                          />
                        </Box>
                      ))}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: 2,
                      flexDirection: {
                        md: "row",
                        xl: "row",
                        sm: "column",
                        xs: "column",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: { md: "45%", xl: "45%", sm: "100%", xs: "100%" },
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        fontWeight={600}
                        fontSize={14}
                      >
                        Shipping Address
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        fontWeight={400}
                        fontSize={12}
                        sx={{
                          maxWidth: "100%",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {singleOrder?.address?.firstName}{" "}
                        {singleOrder?.address?.lastName}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        fontWeight={400}
                        fontSize={12}
                        sx={{
                          maxWidth: "100%",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {singleOrder?.address?.mobile},
                        {singleOrder?.address?.address}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        fontWeight={400}
                        fontSize={12}
                        sx={{
                          maxWidth: "100%",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {singleOrder?.address?.apartment} ,
                        {singleOrder?.address?.area}
                        {singleOrder?.address?.city},{" "}
                        {singleOrder?.address?.state},{" "}
                        {singleOrder?.address?.country},
                        {singleOrder?.address?.pinCode}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: { md: "50%", xl: "50%", sm: "100%", xs: "100%" },
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: 0.5,
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="initial"
                        fontWeight={600}
                        fontSize={14}
                      >
                        Billing Details
                      </Typography>
                      {/* {console.log(singleOrder, "singleOrder")} */}
                      {singleOrder?.orderDetails?.netAmount && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={400}
                            fontSize={12}
                          >
                            Subtotal
                          </Typography>
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={600}
                            fontSize={12}
                          >
                            {SkPrice(singleOrder?.orderDetails?.netAmount)}
                          </Typography>
                        </Box>
                      )}
                      {singleOrder?.orderDetails?.taxAmount && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={400}
                            fontSize={12}
                          >
                            Estimated Tax
                          </Typography>
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={600}
                            fontSize={12}
                          >
                            {SkPrice(singleOrder?.orderDetails?.taxAmount)}
                          </Typography>
                        </Box>
                      )}

                      {singleOrder?.orderDetails?.totalOfferDiscount && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={400}
                            fontSize={12}
                          >
                            Discount
                          </Typography>
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={600}
                            fontSize={12}
                          >
                            -{SkPrice(singleOrder?.orderDetails?.totalOfferDiscount)}
                          </Typography>
                        </Box>
                      )}
                      {singleOrder?.orderDetails?.orderTotal && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={400}
                            fontSize={12}
                          >
                            Total
                          </Typography>
                          <Typography
                            variant="body1"
                            color="initial"
                            fontWeight={600}
                            fontSize={12}
                          >
                            {SkPrice(singleOrder?.orderDetails?.orderTotal)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                      p: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{
                        textTransform: "capitalize",
                        my: 1,
                      }}
                      onClick={() => {
                        dispatch(handleOrderOpen(false));
                        // if (EComm) {
                        //   navigate("/e_home-page");
                        // } else {
                        //   navigate("/");
                        // }
                        navigate("/order-page");
                        // dispatch(handleSetOrderEComm(false));
                        dispatch(handleSetOrderId(false));
                      }}
                    >
                      see your all orders
                    </Button>
                  </Box>
                </Box>
              )
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default OrderModal;
