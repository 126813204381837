import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import { sanitize } from "dompurify";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GoogleTranslate from "../GoogleTranslator";

const Footer = () => {
  const { company } = useSelector((state) => state.isCompany);
  // console.log(company, "company");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "65vh",
          bgcolor: "rgba(40, 39, 39, 1)",
          display: { xs: "none", sm: "none", md: "flex", xl: "flex" },

          // alignItems: "center",
          p: 4,
          flexDirection: "column",
          // gap:2
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            flexDirection: { xs: "column", sm: "column", md: "row", xl: "row" },
          }}
        >
          <Box
            sx={{
              width: "10%",
              height: "30%",
              border: 2,
              borderColor: "white.main",
              borderRadius: 2,
            }}
            component={Link}
            to={"/"}
          >
            <img 
 draggable="false" 
loading="lazy"
              // src={require("../../assets/images/logo1.png")}
              src={`${process.env.REACT_APP_URI}/${company?.footer_logo}`}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../assets/images/logo1.png");
              }}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "8px",
              }}
              alt="..."
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                color: "yellow.main",
                fontFamily: "Inter",
                fontSize: 15,
                fontWeight: 300,
              }}
            >
              Quick Links
            </Typography>
            <Link to={"/homepage"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "white.main",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 300,
                  "&:hover": {
                    color: "third.main",
                    cursor: "pointer",
                  },
                }}
              >
                Home
              </Typography>
            </Link>
            <Link to={"/about-page"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "white.main",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 300,
                  "&:hover": {
                    color: "third.main",
                    cursor: "pointer",
                  },
                }}
              >
                About Us
              </Typography>
            </Link>
            {/* <Link to={"/Faq-page"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "white.main",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 300,
                }}
              >
                Blogs
              </Typography>
            </Link> */}
            <Link to={"/contact-page"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "white.main",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 300,
                  "&:hover": {
                    color: "third.main",
                    cursor: "pointer",
                  },
                }}
              >
                Contact Us
              </Typography>
            </Link>
            <Link to={"/temsandconditon-page"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "white.main",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 300,
                  "&:hover": {
                    color: "third.main",
                    cursor: "pointer",
                  },
                }}
              >
                Terms & Condition
              </Typography>
            </Link>
            <Link to={"/privacy-Policy-page"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "white.main",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 300,
                  "&:hover": {
                    color: "third.main",
                    cursor: "pointer",
                  },
                }}
              >
                Privacy Policy
              </Typography>
            </Link>
            <Link to={"/faq-page"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "white.main",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 300,
                  "&:hover": {
                    color: "third.main",
                    cursor: "pointer",
                  },
                }}
              >
                FAQ
              </Typography>
            </Link>
            <Link to={"/refund-policy-page"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "white.main",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 300,
                  "&:hover": {
                    color: "third.main",
                    cursor: "pointer",
                  },
                }}
              >
                Refund Policy
              </Typography>
            </Link>
            <Link
              to={"/shipping-and-delivery-page"}
              style={{ textDecoration: "none" }}
            >
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "white.main",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 300,
                  "&:hover": {
                    color: "third.main",
                    cursor: "pointer",
                  },
                }}
              >
                Shipping and delivery
              </Typography>
            </Link>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                color: "yellow.main",
                fontFamily: "Inter",
                fontSize: 15,
                fontWeight: 300,
              }}
            >
              Address
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "white.main",
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 300,
                  maxWidth: "250px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 6,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitize(company?.address),
                }}
              />

              {/* {company?.address}
              </Typography> */}
            </Box>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                color: "white.main",
                fontFamily: "Inter",
                fontSize: 14,
                fontWeight: 300,
                maxWidth: "250px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Phone: +91 {company?.phone}
            </Typography>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                color: "white.main",
                fontFamily: "Inter",
                fontSize: 14,
                fontWeight: 300,
              }}
            >
              Email: {company?.email}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box
              sx={{
                width: 220,
                height: 60,
                borderRadius: 1.2,
              }}
              // onClick={() => {
              //   window.open(company?.linkedin);
              // }}
            >
              {" "}
              <a
                href={`${company?.playstoreLink}`}
                target={"_blank"}
                style={{
                  width: "100%",
                  height: "100%",
                }} rel="noreferrer"
              >
                <img 
 draggable="false" 
loading="lazy"
                  src={require("../../assets/images/google.png")}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    borderRadius: "8px",
                  }}
                  alt="..."
                />
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                variant="body1"
                color="initial"
                sx={{ color: "yellow.main", fontSize: 15, fontFamily: "Inter" }}
              >
                Follow Us:
              </Typography>
              <Tooltip title="facebook" arrow>
                <IconButton
                  aria-label="facebook"
                  onClick={() => {
                    window.open(company?.facebook);
                  }}
                  sx={{
                    color: "white.main",
                    p: 0.2,
                    border: 1,
                    borderRadius: 1.5,
                    borderColor: "transparent",
                    "&:hover": {
                      color: "third.main",
                      border: 1,
                      borderRadius: 1.5,
                      borderColor: "third.main",
                      p: 0.2,
                    },
                  }}
                >
                  <Facebook />
                </IconButton>
              </Tooltip>
              <Tooltip title="instagram" arrow>
                <IconButton
                  aria-label="instagram"
                  onClick={() => {
                    window.open(company?.instagram);
                  }}
                  sx={{
                    color: "white.main",
                    p: 0.2,
                    border: 1,
                    borderRadius: 1.5,
                    borderColor: "transparent",
                    "&:hover": {
                      color: "third.main",
                      border: 1,
                      borderRadius: 1.5,
                      borderColor: "third.main",
                      p: 0.2,
                    },
                  }}
                >
                  <Instagram />
                </IconButton>{" "}
              </Tooltip>{" "}
              <Tooltip title="twitter" arrow>
                <IconButton
                  aria-label="twitter"
                  onClick={() => {
                    window.open(company?.twitter);
                  }}
                  sx={{
                    color: "white.main",
                    p: 0.2,
                    border: 1,
                    borderRadius: 1.5,
                    borderColor: "transparent",
                    "&:hover": {
                      color: "third.main",
                      border: 1,
                      borderRadius: 1.5,
                      borderColor: "third.main",
                      p: 0.2,
                    },
                  }}
                >
                  <Twitter />
                </IconButton>{" "}
              </Tooltip>{" "}
              <Tooltip title="linkedin" arrow>
                <IconButton
                  aria-label="linkedin"
                  onClick={() => {
                    window.open(company?.linkedin);
                  }}
                  sx={{
                    color: "white.main",
                    p: 0.2,
                    border: 1,
                    borderRadius: 1.5,
                    borderColor: "transparent",
                    "&:hover": {
                      color: "third.main",
                      border: 1,
                      borderRadius: 1.5,
                      borderColor: "third.main",
                      p: 0.2,
                    },
                  }}
                >
                  <LinkedIn />
                </IconButton>{" "}
              </Tooltip>
              {/* <Box
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                  cursor: "pointer",
                  p: 0.1,
                  "&:hover": {
                    backgroundColor: "third.main",
                    border: 1,
                    borderRadius: "50%",
                    cursor: "pointer",
                  },
                }}
                // onClick={() => {
                //   window.open(company?.facebook);
                // }}
              >
                <a
                  href={`${company?.facebook}`}
                  target={"_blank"}
                  style={{
                    width: "80%",
                    height: "80%",
                  }}
                >
                  <img 
 draggable="false" 
loading="lazy"
                    src={require("../../assets/images/facebook.png")}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="..."
                  />
                </a>
              </Box>
              <Box
                sx={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                // onClick={() => {
                //   window.open(company?.instagram);
                // }}
              >
                <a
                  href={`${company?.instagram}`}
                  target={"_blank"}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img 
 draggable="false" 
loading="lazy"
                    src={require("../../assets/images/insta.png")}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="..."
                  />
                </a>
              </Box>
              <Box
                sx={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                // onClick={() => {
                //   window.open(company?.twitter);
                // }}
              >
                {" "}
                <a
                  href={`${company?.twitter}`}
                  target={"_blank"}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img 
 draggable="false" 
loading="lazy"
                    src={require("../../assets/images/twitter.png")}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="..."
                  />
                </a>
              </Box>

              <Box
                sx={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                onClick={() => {
                  // window.open(company?.linkedin);
                  const a = document.createElement("a");
                  a.target = "_blank";
                  a.href = company?.linkedin;
                  a.click();
                }}
              >
                {" "}
                {/* <a
                  href={`${company?.linkedin}`}
                  target={"_blank"}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                > */}
              {/* <img 
 draggable="false" 
loading="lazy"
                  src={require("../../assets/images/linked.png")}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="..."
                /> */}
              {/* </a> */}
              {/* </Box> */}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: "white.main",
              fontFamily: "Inter",
            }}
          >
            Copyright © 2023 Easy Solution Services. All Rights Reserved.
          </Typography>

          <GoogleTranslate />
        </Box>
      </Box>
    </>
  );
};

export default Footer;
