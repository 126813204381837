// import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
// import thunk from "redux-thunk";
// import alertSlice from "../alertSlice";
// import loginSlice from "../LoginSlice";
// import userSlice from "../festures/userSlice";
// import ProfileSlice from "../ProfileSlice";
// import serviceSlice from "../festures/serviceSlice";
// import homeSlice from "../festures/homeSlice";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
// export default configureStore(
//   {
//     reducer: {
//       isAlert: alertSlice,
//       isLoginModal: loginSlice,
//       isProfileModal: ProfileSlice,
//       isUsers: userSlice,
//       isServices: serviceSlice,
//       isHomes: homeSlice,
//     },
//   },
//   window.__REDUX_DEVTOOLS_EXTENSION__ &&
//     window.__REDUX_DEVTOOLS_EXTENSION__(applyMiddleware(thunk))
// );
// // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// // const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
// // export default store;

import {
  applyMiddleware,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // You can choose different storage options based on your needs
import thunk from "redux-thunk";
import alertSlice from "../alertSlice";
import loginSlice from "../LoginSlice";
import userSlice from "../festures/userSlice";
import ProfileSlice from "../ProfileSlice";
import serviceSlice from "../festures/serviceSlice";
import homeSlice from "../festures/homeSlice";
import companySlice from "../festures/companySlice";
import couponSlice from "../festures/couponSlice";
import cartSlice from "../festures/cartSlice";
import addressSlice from "../festures/addressSlice";
import orderSlice from "../festures/orderSlice";
import OrderModalSlice from "../OrderModalSlice";
import NotiSlice from "../NotiSlice";
const persistConfig = {
  key: "root", // key is the key in the storage that will be used to store the data
  storage, // the storage engine you are using (imported from redux-persist)
  whitelist: ["isHomes", "isOrders", "isCarts"], // List the names of slices you want to persist, in this case, only "isHomes" will be persisted
};
const rootReducer = combineReducers({
  isAlert: alertSlice,
  isLoginModal: loginSlice,
  isProfileModal: ProfileSlice,
  isOrderModal: OrderModalSlice,
  isUsers: userSlice,
  isServices: serviceSlice,
  isHomes: homeSlice,
  isCompany: companySlice,
  isCoupons: couponSlice,
  isCarts: cartSlice,
  isAddress: addressSlice,
  isOrders: orderSlice,
  isNoti: NotiSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const Store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
});

const persistStores = persistStore(Store);

export { Store, persistStores };
