import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../Utils/Api";
import axios from "axios";

export const getNotification = createAsyncThunk(
  "getNotification",
  async (url) => {
    try {
      const res = await http.get(url);
      return res?.data;
    } catch (error) {
      return { data: null };
    }
  }
);
export const getNotificationCount = createAsyncThunk(
  "getNotificationCount",
  async (url) => {
    try {
      const res = await http.get(url);
      return res?.data;
    } catch (error) {
      return { data: null };
    }
  }
);

const NotiSlice = createSlice({
  name: "notification",
  initialState: {
    isOpen: false,
    loading: false,
    Notification: null,
    count: null,
  },
  reducers: {
    openNoti: (state, action) => {
      state.isOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.Notification = action?.payload?.data;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getNotificationCount.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action?.payload;
      });
  },
});

export const { openNoti } = NotiSlice.actions;

export default NotiSlice.reducer;
