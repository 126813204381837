import { Badge } from "@mui/material";
import React from "react";

const Badges = ({ children,count }) => {
  return (
    
      <Badge badgeContent={count} color="primary">
        {children}
      </Badge>
  );
};

export default Badges;
